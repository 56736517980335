import React, { Component, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"


/**
 * TopicPath component
 * @param props.pageId {string} - リスト項目の一意な名前 (keyに使用）
 * @param props.crumbs {object[]} - useBreadcrumb の返り値
 * @param props.icon {string} - アイコンの種類 (default "default")
 *                              "default" ... 区切りアイコン
 *                              "home" ... Homeアイコン
 * @return React Element {*}
 */
interface ITopicPathProps {
    pageId: string,
    crumbs: object[],
    icon?: string,
}

const TopicPath = (props: ITopicPathProps) => {
    const containerElementRef = useRef<HTMLInputElement>(null)
    const listItems = props.crumbs.map((crumb: any, index: number) => {
        const icon = index <= 0 ? "home" : "default"
        const key = `${props.pageId}_${index}`

        return (
            <TopicPathItem
                path={crumb.pathname}
                label={crumb.crumbLabel}
                style={{ ...crumb.crumbStyle }}
                activeStyle={{ ...crumb.crumbActiveStyle }}
                icon={icon}
                key={key}
            />
        )
    })


    useEffect(() => {
        scrollToLeftmost()
    }, [])


    // overflow-x が scroll であれば左端までスクロールさせる
    const scrollToLeftmost = () => {
        const element = containerElementRef.current
        if (element) {
            const newScroll = element.scrollWidth - element.clientWidth
            if (element.scrollLeft < newScroll) element.scrollLeft = newScroll
        }
    }


    return (
        <div
            className="topic-path-container"
            ref={containerElementRef}
        >
            <ul className="topic-path">
                {listItems}
            </ul>
        </div>
    )
}

TopicPath.defaultProps = {
    pageId: "",
    icon: "default",
}


/**
 * TopicPathItem component
 *
 * @param label {string} - リスト項目のラベル
 * @param path {string} - リンク先のパス
 * @param style {object} - インラインスタイル
 * @param activeStyle {object} - アクティブ時のインラインスタイル
 * @param props.icon {string} - アイコンの種類 (default "default")
 *                              "default" ... 区切りアイコン
 *                              "home" ... Homeアイコン
 * @return React Element {*}
 */
interface ITopicPathItemProps {
    label: string,
    path: string,
    style?: object,
    activeStyle?: object,
    icon?: string,
}

const TopicPathItem = (props: ITopicPathItemProps) => {
    // 登録している Font Awesome Icon を取得する
    const falChevronRight = findIconDefinition({ prefix: "fal", iconName: "chevron-right" })
    const falHomeAlt = findIconDefinition({ prefix: "fal", iconName: "home-alt" })
    const falHistory = findIconDefinition({ prefix: "fal", iconName: "history"})

    const icon = props.icon == "home" ? falHistory : falChevronRight

    return (
        <li className="topic-path__item">
            <FontAwesomeIcon icon={icon} size="2x" pull="left" fixedWidth/>
            <Link to={props.path} className="link" style={{ ...props.style }} activeStyle={{ ...props.activeStyle }}>
                {props.label}
            </Link>
        </li>
    )
}

TopicPathItem.defaultProps = {
    icon: "default",
}


export default TopicPath