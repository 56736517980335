import React from "react"
import { Link } from "gatsby"


/**
 * FooterMenu component
 * フッターメニュー
 *
 * @param {any} style? - インラインスタイル
 *
 * @return {*} React Element
 */
interface IFooterMenuProps {
    style?: React.CSSProperties,
    className?: string,
}

const FooterMenu = (props: IFooterMenuProps) => {
    let rootContainerClassName = `grid-container full`
    if (props.className) rootContainerClassName = `${rootContainerClassName} ${props.className}`

    return (
        <div className={rootContainerClassName} style={props.style}>
            <div className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div className="footer-menu grid-x grid-margin-x">
                            <div className="cell small-12 medium-4">
                                <p className="footer-menu__title">事業情報</p>
                                <ol>
                                    <li>
                                        <Link to="/">Top</Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/#productDevelopment"
                                            partiallyActive={true}
                                        >
                                            製品開発 (受託・請負)
                                        </Link>
                                        <ol>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_circuitAndBoardDesign"
                                                    partiallyActive={true}
                                                >
                                                    回路・基板設計
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_machineAndMechanismDesign"
                                                    partiallyActive={true}
                                                >
                                                    機械・機構設計
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_softwareDevelop"
                                                    partiallyActive={true}
                                                >
                                                    ソフトウェア開発
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_designDevelop"
                                                    partiallyActive={true}
                                                >
                                                    デザイン開発
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_fabricationMountingAndAssembly"
                                                    partiallyActive={true}
                                                >
                                                    製作・実装・組立
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_evaluationTest"
                                                    partiallyActive={true}
                                                >
                                                    評価試験
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopment_field_massProductionSupport"
                                                    partiallyActive={true}
                                                >
                                                    量産支援
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/#productDevelopmentWorks"
                                                    partiallyActive={true}
                                                >
                                                    開発事例
                                                </Link>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <Link
                                            to="/#inHouseProductDevelopment"
                                            partiallyActive={true}
                                        >
                                            自社製品開発
                                        </Link>
                                        <ol>
                                            <li>
                                                <Link
                                                    to="/#inHouseProductDevelopment_hemsControlPanel"
                                                    partiallyActive={true}
                                                >
                                                    HEMS制御盤
                                                </Link>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div className="cell small-12 medium-4">
                                <p className="footer-menu__title">会社情報</p>
                                <ol>
                                    <li>
                                        <Link to="/company/">会社概要</Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/company/#cooperationWithUniversities"
                                            partiallyActive={true}
                                        >
                                            大学との連携
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/company/#accessMap"
                                            partiallyActive={true}
                                        >
                                            アクセス
                                        </Link>
                                        <ol>
                                            <li>
                                                <Link
                                                    to="/company/#accessMap_publicTransport"
                                                    partiallyActive={true}
                                                >
                                                    公共交通機関でお越しの場合
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/company/#accessMap_car"
                                                    partiallyActive={true}
                                                >
                                                    お車でお越しの場合
                                                </Link>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <Link to="/information/">インフォメーション</Link>
                                    </li>
                                    <li>
                                        <Link to="/recruit/">採用情報</Link>
                                    </li>
                                </ol>
                            </div>
                            <div className="cell small-12 medium-4">
                                <p className="footer-menu__title">お問い合わせ</p>
                                <ol>
                                    <li>
                                        <Link
                                            to="/contact/"
                                        >
                                            お問合せフォーム
                                        </Link>
                                    </li>
                                </ol>
                                <p className="footer-menu__title">サイトについて</p>
                                <ol>
                                    <li>
                                        <Link
                                            to="/policy/"
                                        >
                                            プライバシーポリシー
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/informationSecurityPolicy/"
                                        >
                                           情報セキュリティ基本方針
                                        </Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FooterMenu