import React, { useEffect, useState } from "react"

// components
import { IMenuList } from "./index"
import HeaderMenuItem from "./HeaderMenuItem"


/**
 * Header menu component
 *
 * @param {IMenuList[]} menuList - メニューリスト
 * @param {boolean} isHeaderOpened? - ヘッダーを開いているか (default: true)
 * @param {boolean} immediate? - アニメーション結果即時反映させる (default: false)
 * @param {any} style? - インラインスタイル
 */
interface IHeaderMenuProps {
    menuList: IMenuList[],
    isHeaderOpened?: boolean,
    immediate?: boolean,
    style?: any,
}

const HeaderMenu = (props: IHeaderMenuProps) => {
    const [listItems, setListItems] = useState([])

    useEffect(() => {
        createMenuItems()
    }, [])

    // メニュー項目を作成する
    const createMenuItems = () => {
        let items: any = []
        const defaultStyles = {
            opacity: props.isHeaderOpened ? 1 : 0,
            marginTop: props.isHeaderOpened ? 20 : 0,
            marginBottom: props.isHeaderOpened ? 20 : 0,
            height: props.isHeaderOpened ? 40 : 0,
        }

        if (props.menuList.length > 0) {
            for (let i = 0; i < props.menuList.length; i++) {
                const item = props.menuList[i]
                const itemEl = (
                    <HeaderMenuItem
                        key={`menuItem_${item.id}`}
                        className="cell header-menu-item"
                        style={defaultStyles}
                        href={item.href}
                    >
                        {item.label}
                    </HeaderMenuItem>
                )
                items.push(itemEl)
            }

            setListItems(items)
        }
    }


    return (
        <ol
            className="grid-x medium-up-2 large-up-4 grid-margin-x header-menu"
            style={props.style}
        >
            {listItems}
        </ol>
    )
}
HeaderMenu.defaultProps = {
    isHeaderOpened: true,
    immediate: false,
    style: {},
}


export default HeaderMenu