import React, { useCallback, useEffect, useRef, useState } from "react"
import CSS from "csstype"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { gsap } from "gsap"
import { easePolyInOut, easeCubicInOut, easeCubicIn, easeCubicOut } from "d3-ease"


/**
 * MenuButton component
 * @param {string} size? - ボタンのサイズ  default: '100%'
 * @param {string} className? - スタイルクラス名
 * @param {object} style? - インラインスタイル
 */
interface IMenuButtonProps {
    size?: string,
    displayState: "close" | "small" | "large" | undefined,
    className?: string | undefined,
    style?: CSS.Properties,
    hoverColor?: string,
    onClickCallback: any,
}

const MenuButton = (props: IMenuButtonProps) => {
    const tween = gsap.timeline({ paused: true })
    const containerRef = useRef(null)


    useEffect(() => {
        if (props.displayState == "large") {
            addAnimationPush(tween, true)
            tween.play()
            return
        }
            addAnimationNormal(tween, true)
            tween.play()
    }, [])


    useEffect(() => {
        if (props.displayState == "large") {
            tween.clear()
            addAnimationPush(tween)
            tween.play()
            return
        }

        tween.clear()
        addAnimationNormal(tween)
        tween.play()
    }, [props.displayState])


    // 普通の状態
    const addAnimationNormal = (tween: any, immediate: boolean = false) => {
        tween.to(containerRef.current, {
            duration: immediate ? 0 : 0.2,
            ease: easeCubicIn,
            backgroundColor: props.hoverColor,
            border: `none`,
        })
        tween.to(containerRef.current, {
            duration: immediate ? 0 : 0.2,
            ease: easeCubicIn,
            backgroundColor: `rgb(255, 255, 255)`,
            color: `rgb(0, 0, 0)`,
        })
    }


    // 押下の状態
    const addAnimationPush = (tween: any, immediate: boolean = false) => {
        tween.to(containerRef.current, {
            duration: immediate ? 0 : 0.2,
            ease: easeCubicIn,
            backgroundColor: props.hoverColor,
        })
        tween.to(containerRef.current, {
            duration: immediate ? 0 : 0.2,
            ease: easeCubicIn,
            backgroundColor: `rgb(0, 0, 0)`,
            color: `rgb(255, 255, 255)`,
            border: `2px solid rgb(255, 255, 255)`,
        })
    }


    // マウスが重なったときの状態
    const addAnimationMouseEnter = (tween: any, immediate: boolean = false) => {
        tween.to(containerRef.current, {
            duration: immediate ? 0 : 0.2,
            ease: easePolyInOut,
            scale: (1.1),
            color: props.hoverColor,
        })
    }


    // マウスが外れたときの状態
    const addAnimationMouseLeave = (tween: any, immediate: boolean = false) => {
        tween.to(containerRef.current, {
            duration: 0.2,
            ease: easePolyInOut,
            scale: (1.0),
            color: props.displayState == "large" ? `rgb(255, 255, 255)` : `rgb(0, 0, 0)`,
        })
    }


    const onClick = () => {
        props.onClickCallback(toggleStatus())
    }


    const onMouseEnter = () => {
        //tween.clear()
        addAnimationMouseEnter(tween)
        tween.play()
    }


    const onMouseLeave = () => {
        //tween.clear()
        addAnimationMouseLeave(tween)
        tween.play()
    }


    const toggleStatus = () => {
       if (props.displayState == "small") return "large"

       return "small"
    }


    return (
        <a
            ref={containerRef}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`menu-button ${props.className}`}
            style={{
                height: props.size,
                width: props.size,
                ...props.style,
            }}
            tabIndex={1}
        >
            <Icon on={props.displayState == "large"}/>
        </a>
    )
}

MenuButton.defaultProps = {
    size: `100%`,
    hoverColor: `rgb(39, 185, 185)`,
}


/**
 * Icon component
 * @param {boolean} on - アイコンを切り替える
 */
interface IIconProps {
    on?: boolean,
}

const Icon = (props: IIconProps) => {
    // 登録している Font Awesome Icon を取得する
    const falAlignJustify = findIconDefinition({ prefix: "fal", iconName: "align-justify" })
    const falTimes = findIconDefinition({ prefix: "fal", iconName: "times" })

    const [icon, setIcon] = useState(falAlignJustify)


    useEffect(() => {
        if (props.on) {
            setIcon(falTimes)
            return
        }

        setIcon(falAlignJustify)
    }, [props.on])


    return (
        <span>
            <span className="utility-visually-hidden" data-testid="icon-label">Header close</span>
            <span className="menu-button__icon" aria-hidden="true">
                <FontAwesomeIcon icon={icon} size="2x" fixedWidth/>
            </span>
        </span>
    )
}

Icon.defaultProps = {
    on: false,
}


export default MenuButton