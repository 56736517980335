import React, { useEffect, useRef, Fragment } from "react"
import { Link } from "gatsby"
import throttle from "lodash.throttle"
import { gsap } from "gsap"
import { easePolyIn, easePolyInOut, easePolyOut, easeSinInOut } from "d3-ease"


// Components
import MenuButton from "./MenuButton"
import HeaderMenu from "./HeaderMenu"
import TopicPath from "./TopicPath"
import SiteMapButton from "./SiteMapButton"
import FooterMenu from "../Footer/FooterMenu"
import { animated } from "react-spring/renderprops"


/**
 * Header component
 *
 * @param {string} pageId -
 * @param {any} crumbs -
 * @param {boolean} headerSmallOfPageTop? - ページの一番上でヘッダーをたたむ (default false)
 * @param {boolean} headerSmallOfSticky? - スクロールに追随するときヘッダーをたたむ (default true)
 * @param {boolean} coverUp? - ヘッダーを固定したとき、要素の上に被せる (default false)
 * @param {string} siteTitle - サイトのタイトル
 *
 *
 * 以下、前の内容
 * @param location {object} - gatsby の location
 * @param crumbLabel {string} - パンくずリストに表示する名称
 * @param headerOpened {boolean} -ヘッダーを開く (default undefined)
 * @param bgTransparent {boolean} - ヘッダーの背景を透明にする (default false)
 * @param fixHeader {boolean} - ヘッダーを固定する (default false)
 * @returns React Element {*}
 */
interface IHeaderV2Props {
    pageId: string,
    crumbs: any,
    headerSmallOfPageTop?: boolean,
    headerSmallOfSticky?: boolean,
    coverUp?: boolean,
    siteTitle: string,
}

interface IHeaderV2State {
    /**
     * マウント後の準備ができているか
     */
    isReady: boolean,
    /**
     * ヘッダーを固定したとき、要素の上に被せる
     */
    coverUp: boolean | undefined,
    /**
     * 現在の画面サイズ
     */
    screenSize: "small" | "medium" | "large" | "xlarge" | "xxlarge" | undefined
    /**
     * ヘッダーの表示状態
     */
    isHeaderDisplayed: boolean,
    /**
     * ヘッダーメニューの表示状態
     */
    isHeaderMenuDisplayed: boolean,
    /**
     * トピックパスの表示状態
     */
    isTopicPathDisplayed: boolean,
    /**
     * サイトマップボタンの表示状態
     */
    isSitemapButtonDisplayed: boolean,
    /**
     * サイトマップの表示状態
     */
    isSitemapDisplayed: boolean,
    /**
     * ヘッダーコンテナ内のスクロールを有効にしているか
     */
    isEnableInlineScroll: boolean,
    /**
     * 現在の縦スクロールが 0 (一番上) か
     */
    isWindowScrollYZero: boolean | undefined,
    /**
     * SVGロゴの塗りつぶし色
     */
    svgLogoFillColor: string,
    /**
     * ヘッダーの高さ
     * インナー要素を取得しているのは子要素の影響を受けた高さを取得するため
     */
    innerContainerHeight: number,
    /**
     * Windowの高さ
     */
    windowHeight: number,
    /**
     * Windowの幅
     */
    windowWidth: number,
}

// メニューリスト
export interface IMenuList {
    label: string,
    href: string,
    id: string,
}

// ブレークポイント
export interface IBreakpoint {
    small: number,
    medium: number,
    large: number,
    xlarge: number,
    xxlarge: number,
}

// 設定
interface IHeaderV2Config {
    /**
     * ヘッダーを小さくしたときの高さ
     */
    headerHeightSmall: number,
    /**
     * ヘッダーを大きくしたときのパディング
     */
    headerOpenLargePaddingTop: number,
    headerOpenLargePaddingBottom: number,
    /**
     * ヘッダーを隠す縦スクロール量
     */
    hideHeaderScrollY: number,
    /**
     * ヘッダーを小さくしたときのロゴの塗り色
     */
    logoFillColorSmall: string,
    /**
     * ヘッダーを大きくしたときのロゴの塗り色
     */
    logoFillColorLarge: string,
    /**
     * ヘッダーを閉じたときの背景色
     */
    headerBgColorClose: string,
    /**
     * ヘッダーを小さくしたときの背景色
     */
    headerBgColorSmall: string,
    /**
     * スクロールに追随するヘッダーを小さくしたときの背景色
     */
    headerBgColorSmallSticky: string,
    /**
     * ヘッダーを大きくしたときの背景色
     */
    headerBgColorLarge: string,
    /**
     * ヘッダー内のスクロールを有効にするWindowの最小高さ
     */
    windowMinimumHeightToEnableInlineScrollingHeader: number,
    /**
     * windowsのリサイズイベントの発火を遅延させる(ms)
     */
    windowResizeWaitTime: number,
    /**
     * windowsのスクロールイベントの発火を遅延させる(ms)
     */
    windowScrollWaitTime: number,
    /**
     * ヘッダーメニューの開閉に使用するスタイルクラス名
     *   - 複数の要素が対象なのでRefではなくスタイルクラス名を使っている
     */
    menuListItemClassNameTweenTarget: string,
    /**
     * メニューリスト
     */
    menuList: IMenuList[],
    /**
     * ブレークポイント
     */
    breakpoint: IBreakpoint,
}

class HeaderV2 extends React.Component<IHeaderV2Props, IHeaderV2State> {
    public static defaultProps: IHeaderV2Props = {
        pageId: "",
        crumbs: [],
        headerSmallOfPageTop: false,
        headerSmallOfSticky: true,
        coverUp: false,
        siteTitle: "",
    }

    private readonly config: IHeaderV2Config = {
        headerHeightSmall: 100,
        headerOpenLargePaddingTop: 30,
        headerOpenLargePaddingBottom: 30,
        hideHeaderScrollY: 1000,
        logoFillColorSmall: `rgb(0, 0, 0)`,
        logoFillColorLarge: `rgb(255, 255, 255)`,
        headerBgColorClose: `rgba(0, 0, 0, 0)`,
        headerBgColorSmall: `rgba(0, 0, 0, 0)`,
        headerBgColorSmallSticky: `rgba(0, 0, 0, 0)`,
        headerBgColorLarge: `rgba(0, 0, 0, 0.8)`,
        windowMinimumHeightToEnableInlineScrollingHeader: 800,
        windowResizeWaitTime: 800,
        windowScrollWaitTime: 400,
        menuListItemClassNameTweenTarget: ".header-menu-item",
        menuList: [
            {
                label: "事業情報",
                href: "/",
                id: "menu1",
            },
            {
                label: "会社情報",
                href: "/company/",
                id: "menu2",
            },
            {
                label: "アクセス",
                href: "/company/#accessMap",
                id: "menu3",
            },
            {
                label: "採用情報",
                href: "/recruit/",
                id: "menu4",
            },
            {
                label: "お問い合わせ",
                href: "/contact/",
                id: "menu5",
            },
        ],
        breakpoint: {
            small: 0,
            medium: 640,
            large: 1024,
            xlarge: 1200,
            xxlarge: 1440,
        },

    }

    // Refs
    private headerWrapperEl: any
    private headerEl: any
    private headerContainerEl: any
    private topicPathEl: any
    private sitemapButtonContainerEl: any
    private sitemapButtonEl: any
    private sitemapEl: any

    // throttle で実行間隔をコントロールするイベント
    private readonly boundResize: any
    private readonly boundScroll: any

    // gsapオブジェクト
    private tween: any

    // ヘッダーの表示状態
    private displayState: "small" | "large" | undefined

    // ヘッダーがスクロールに追随している
    private isSticky: boolean

    // ヘッダーメニューのアニメーションを即時反映する
    private headerMenuImmediate: boolean

    // アニメーションの追加を禁止する
    private disableAddAnimation: boolean

    // Windowスクロール量
    private scrollY: number
    private prevScrollY: number
    // スクロール方向
    private scrollDirection: "up" | "down" | undefined

    constructor(props: IHeaderV2Props) {
        super(props)
        this.state = {
            isReady: false,
            screenSize: undefined,
            coverUp: this.props.coverUp,
            innerContainerHeight: 0,
            isEnableInlineScroll: false,
            isWindowScrollYZero: undefined,
            svgLogoFillColor: this.config.logoFillColorSmall,
            isHeaderDisplayed: true,
            isHeaderMenuDisplayed: false,
            isTopicPathDisplayed: false,
            isSitemapButtonDisplayed: false,
            isSitemapDisplayed: false,
            windowHeight: 0,
            windowWidth: 0,
        }
        this.tween = gsap.timeline({ paused: true })

        this.headerWrapperEl = React.createRef<HTMLDivElement>()
        this.headerEl = React.createRef<HTMLDivElement>()
        this.headerContainerEl = React.createRef<HTMLDivElement>()
        this.topicPathEl = React.createRef<HTMLDivElement>()
        this.sitemapButtonContainerEl = React.createRef<HTMLDivElement>()
        this.sitemapButtonEl = React.createRef<HTMLDivElement>()
        this.sitemapEl = React.createRef<HTMLDivElement>()

        this.displayState = undefined
        this.isSticky = false
        this.headerMenuImmediate = false
        this.disableAddAnimation = false

        this.scrollY = 0
        this.prevScrollY = 0
        this.scrollDirection = undefined

        this.boundResize = throttle(this.windowResize.bind(this), this.config.windowResizeWaitTime)
        this.boundScroll = throttle(this.windowScroll.bind(this), this.config.windowScrollWaitTime)
    }


    componentDidMount(): void {
        this.updateScrollY()
        this.updateIsWindowScrollYZero()
        this.updateWindowHeight()
        this.updateWindowWidth()
        this.updateInnerContainerHeight()

        if (typeof window !== "undefined") {
            window.addEventListener("resize", this.boundResize)
            window.addEventListener("scroll", this.boundScroll, { passive: false })
        }
    }


    componentWillUnmount(): void {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.boundResize)
            window.removeEventListener("scroll", this.boundScroll)
        }
    }


    componentDidUpdate(prevProps: Readonly<IHeaderV2Props>, prevState: Readonly<IHeaderV2State>, snapshot?: any): void {
        if (this.props.coverUp !== prevProps.coverUp) {
            this.setState({ coverUp: this.props.coverUp })
        }

        if (this.state.windowWidth !== prevState.windowWidth) {
            this.didUpdateWindowWidth(this.state.windowWidth, prevState.windowWidth)
        }

        if (this.state.isWindowScrollYZero !== prevState.isWindowScrollYZero) {
            this.didUpdateIsWindowScrollYZero(this.state.isWindowScrollYZero, prevState.isWindowScrollYZero)
        }

        if (this.state.isSitemapDisplayed !== prevState.isSitemapDisplayed) {
            this.didUpdateIsSitemapDisplayed(this.state.isSitemapDisplayed, prevState.isSitemapDisplayed)
        }
    }


    // componentDidUpdate process for state.windowWidth.
    private didUpdateWindowWidth(current: any, prev: any): void {
        if (current > this.config.breakpoint.xxlarge) {
            this.setState({ screenSize: "xxlarge" })
            return
        }

        if (current > this.config.breakpoint.xlarge) {
            this.setState({ screenSize: "xlarge" })
            return
        }

        if (current > this.config.breakpoint.large) {
            this.setState({ screenSize: "large" })
            return
        }

        if (current > this.config.breakpoint.medium) {
            this.setState({ screenSize: "medium" })
            return
        }

        this.setState({ screenSize: "small" })
    }


    // componentDidUpdate process for state.isWindowScrollYZero.
    private didUpdateIsWindowScrollYZero(current: boolean | undefined, prev: boolean | undefined): void {
        if (!this.state.isReady) {
            this.initialProcessOfMounting()
            this.tween.play()
            this.setState({ isReady: true })
            return
        }

        this.switchHeaderSticky()
    }


    // componentDidUpdate process for state.isSitemapDisplayed.
    private didUpdateIsSitemapDisplayed(current: boolean, prev: boolean): void {
        if (current) {
            this.addActionSitemapOpen(this.tween)
            this.tween.play()
            return
        }

        this.addActionSitemapClose(this.tween)
        this.tween.play()
    }


    // マウント時の初期処理
    initialProcessOfMounting(): void {
        // Body要素にスクロール禁止のスタイルクラスが残っていれば削除する
        if (typeof window !== "undefined") {
            const className = "common-body-do-not-scroll"
            const elements = document.getElementsByTagName("body")
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].classList.contains(className)) {
                    elements[i].classList.remove(className)
                }
            }
        }

        // ロゴの塗り色を設定する
        let fillColor = this.config.logoFillColorSmall
        if (this.state.isWindowScrollYZero && !this.state.coverUp) fillColor = this.config.logoFillColorLarge
        this.setState({ svgLogoFillColor: fillColor })

        // 縦スクロール位置が一番上であるときのスタイル設定
        if (this.state.isWindowScrollYZero) {
            this.isSticky = false

            // Position を設定する
            if (this.state.coverUp) {
                this.addAnimationHeaderPositionAbsolute(this.tween)
            } else {
                this.addAnimationHeaderPositionRelative(this.tween)
            }

            // ヘッダーの開閉を設定する
            this.addActionOpenHeader(this.tween, true)
            if (this.props.headerSmallOfPageTop) {
                this.addActionChangeHeaderStateSmall(this.tween, true)
            } else {
                this.addActionChangeHeaderStateLarge(this.tween, true)
            }

            return
        }

        // 縦スクロール位置が一番上ではないときのスタイル設定
        this.isSticky = true

        // Position を設定する
        this.addAnimationHeaderPositionFixed(this.tween)

        // ヘッダーの開閉を設定する
        if (this.props.headerSmallOfSticky) {
            this.addActionChangeHeaderStateSmall(this.tween, true)
        } else {
            this.addActionChangeHeaderStateLarge(this.tween, true)
        }
    }


    // ヘッダーをスクロールに追随する
    addActionHeaderSticky(tween: any, immediate: boolean = false): void {
        this.isSticky = true

        if (this.state.coverUp) {
            this.addAnimationHeaderMoveOffScreenTop(tween, immediate)
        } else {
            this.addActionCloseHeader(tween, immediate)
            this.addAnimationHeaderMoveOffScreenTop(tween, true)
        }

        this.addAnimationHeaderPositionFixed(tween)

        this.addActionOpenHeader(tween, true)

        if (this.props.headerSmallOfSticky) {
            this.addActionChangeHeaderStateSmall(tween, true)
        } else {
            this.addActionChangeHeaderStateLarge(tween, true)
        }

        this.addAnimationHeaderMoveOnScreenTop(tween, immediate)
    }


    // ヘッダーをスクロールに追随しない
    // coverUp が true のとき、下の要素の上に表示する (position: absolute)
    // coverUp が false のとき、相対配置にする (position: relative)
    addActionHeaderNotSticky(tween: any, immediate: boolean = false): void {
        this.isSticky = false

        this.addAnimationHeaderMoveOffScreenTop(tween, immediate)

        if (this.state.coverUp) {
            this.addAnimationHeaderPositionAbsolute(tween)
            if (this.displayState == "large" || !this.props.headerSmallOfPageTop) {
                this.addActionChangeHeaderStateLarge(tween, true)
                // this.addActionOpenHeaderLarge(tween, true)
            }
            this.addAnimationHeaderMoveOnScreenTop(tween, immediate)
        } else {
            this.addActionCloseHeader(tween, true)
            this.addAnimationHeaderPositionRelative(tween)

            // Stickyで大きいヘッダーにしていた場合の処理
            if (this.displayState == "large") {
                this.addActionChangeHeaderStateLarge(tween, true)
                this.addActionOpenHeader(tween, immediate)
                this.addAnimationHeaderMoveOnScreenTop(tween, immediate)
                return
            }

            // Stickyで小さいヘッダーにしていた場合の処理
            if (this.props.headerSmallOfPageTop) {
                this.addActionChangeHeaderStateSmall(tween, true)
            } else {
                this.addActionChangeHeaderStateLarge(tween, true)
            }

            this.addActionOpenHeader(tween, immediate)
            this.addAnimationHeaderMoveOnScreenTop(tween, immediate)
        }
    }


    // ヘッダーを開く
    addActionOpenHeader(tween: any, immediate: boolean = false): void {
        this.addAnimationHeaderOpen(tween, immediate)
        if (!this.state.isHeaderDisplayed) this.setState({ isHeaderDisplayed: true })
    }


    // ヘッダーを閉じる
    addActionCloseHeader(tween: any, immediate: boolean = false): void {
        this.addAnimationHeaderClose(tween, immediate)
        if (this.state.isHeaderDisplayed) this.setState({ isHeaderDisplayed: false })
    }


    // ヘッダーのサイズを小さくする
    addActionChangeHeaderStateSmall(tween: any, immediate: boolean = false): void {
        this.displayState = "small"

        // サイトマップを閉じる
        if (this.state.isSitemapDisplayed) {
            this.addActionSitemapClose(tween, immediate)
        }

        // サイトマップボタンコンテナをヘッダーを閉じるポジションにする
        if (this.state.isSitemapButtonDisplayed) {
            this.addAnimationSitemapButtonContainerClosePosition(tween)
        }

        // ヘッダー内のスクロールを無効にする
        if (this.state.isEnableInlineScroll) {
            this.addActionDisableInlineScrollToHeader(tween, immediate)
        }

        // サイトマップボタンを非表示にする
        if (this.state.isSitemapButtonDisplayed) {
            this.addActionHideSitemapButton(tween, immediate)
        }

        // トピックパスを閉じる
        if (this.state.isTopicPathDisplayed) {
            this.addActionTopicPathClose(tween, immediate)
        }

        if (this.state.isHeaderMenuDisplayed) {
            // ヘッダーメニューを閉じる
            this.addActionHeaderMenuClose(tween, immediate)
        }

        // ヘッダーの状態を小さくする
        this.addAnimationHeaderStateSmall(tween, immediate)

        // ロゴの塗り色を設定する
        let fillColor = this.config.logoFillColorSmall
        if (this.state.isWindowScrollYZero && !this.state.coverUp) fillColor = this.config.logoFillColorLarge
        this.setState({ svgLogoFillColor: fillColor })
    }


    // ヘッダーのサイズを大きくする
    addActionChangeHeaderStateLarge(tween: any, immediate: boolean = false): void {
        this.displayState = "large"

        // スクリーンサイズが小さい場合か、
        // Windowの高さが設定より低い場合は、ヘッダー内のスクロールを有効にする
        if (this.state.screenSize == "small" ||
            this.state.screenSize == "medium" ||
            this.state.windowHeight < this.config.windowMinimumHeightToEnableInlineScrollingHeader
        ) {
            this.addActionEnableInlineScrollToHeader(tween, immediate)
        }

        // ヘッダーの状態を大きくする
        this.addAnimationHeaderStateLarge(tween, immediate)

        // ヘッダーメニューを開く
        if (!this.state.isHeaderMenuDisplayed) {
            this.addAnimationSitemapButtonContainerOpenPosition(tween)
            this.addActionHeaderMenuOpen(tween, immediate)
        }

        // トピックパスを開く
        if (!this.state.isTopicPathDisplayed) {
            this.addActionTopicPathOpen(tween, immediate)
        }

        // サイトマップボタンを表示にする
        if (!this.state.isSitemapButtonDisplayed) {
            this.addActionDisplaySitemapButton(tween, immediate)
        }

        // ロゴの塗り色を設定する
        this.setState({ svgLogoFillColor: this.config.logoFillColorLarge })
    }


    // ヘッダーコンテナ内のスクロールを有効にする
    addActionEnableInlineScrollToHeader(tween: any, immediate: boolean = false): void {
        this.addAnimationEnableInnerScrollToHeader(tween, immediate)
        this.setState({ isEnableInlineScroll: true })
    }


    // ヘッダーコンテナ内のスクロールを無効にする
    addActionDisableInlineScrollToHeader(tween: any, immediate: boolean = false): void {
        this.addAnimationDisableInnerScrollToHeader(tween, immediate)
        this.setState({ isEnableInlineScroll: false })
    }


    // ヘッダーメニューを開く
    addActionHeaderMenuOpen(tween: any, immediate: boolean = false): void {
        this.addAnimationHeaderMenuOpen(tween, immediate)
        this.setState({ isHeaderMenuDisplayed: true })
    }


    // ヘッダーメニューを閉じる
    addActionHeaderMenuClose(tween: any, immediate: boolean = false): void {
        this.addAnimationHeaderMenuClose(tween, immediate)
        this.setState({ isHeaderMenuDisplayed: false })
    }


    // トピックパスを開く
    addActionTopicPathOpen(tween: any, immediate: boolean = false): void {
        this.addAnimationTopicPathOpen(tween, immediate)
        this.setState({ isTopicPathDisplayed: true })
    }


    // トピックパスを閉じる
    addActionTopicPathClose(tween: any, immediate: boolean = false) {
        this.addAnimationTopicPathClose(tween, immediate)
        this.setState({ isTopicPathDisplayed: false })
    }


    // サイトマップボタンを表示にする
    addActionDisplaySitemapButton(tween: any, immediate: boolean = false) {
        this.addAnimationDisplaySitemapButton(tween, immediate)
        this.setState({ isSitemapButtonDisplayed: true })
    }


    // サイトマップボタンを非表示にする
    addActionHideSitemapButton(tween: any, immediate: boolean = false) {
        this.addAnimationHideSitemapButton(tween, immediate)
        this.setState({ isSitemapButtonDisplayed: false })
    }


    // サイトマップを開く
    addActionSitemapOpen(tween: any, immediate: boolean = false): void {
        this.addAnimationSitemapOpen(tween, immediate)
        this.setState({ isSitemapDisplayed: true })
    }


    // サイトマップを閉じる
    addActionSitemapClose(tween: any, immediate: boolean = false): void {
        this.addAnimationSitemapClose(tween, immediate)
        this.setState({ isSitemapDisplayed: false })
    }


    // 現在のアニメーションの再生が終了するまで、アニメーションの追加をロックする
    addConfigLockingAddAnimation(tween: any): void {
        this.disableAddAnimation = true
        this.tween.eventCallback(`onComplete`, () => {
            this.disableAddAnimation = false
        })
    }


    // ヘッダーの配置を相対位置にする
    addAnimationHeaderPositionRelative(tween: any): void {
        tween
        .to(this.headerWrapperEl.current, {
            position: "relative",
        })
    }


    // ヘッダーの配置を絶対位置にする
    addAnimationHeaderPositionAbsolute(tween: any): void {
        tween
        .to(this.headerWrapperEl.current, {
            position: "absolute",
        })
    }


    // ヘッダーの配置を固定絶対位置にする
    addAnimationHeaderPositionFixed(tween: any): void {
        tween
        .to(this.headerWrapperEl.current, {
            position: "fixed",
        })
    }


    // ヘッダーを開く
    addAnimationHeaderOpen(tween: any, immediate: boolean = false): void {
        tween
        .to(this.headerWrapperEl.current, {
            duration: immediate ? 0 : 0.4,
            height: "auto",
            overflow: "visible",
        })
    }


    // ヘッダーを閉じる
    addAnimationHeaderClose(tween: any, immediate: boolean = false): void {
        tween
        .to(this.headerWrapperEl.current, {
            duration: immediate ? 0 : 0.4,
            height: 0,
            overflow: "hidden",
        })
    }


    // ヘッダーの状態を小さくする
    addAnimationHeaderStateSmall(tween: any, immediate: boolean = false): void {
        let bgColor = this.config.headerBgColorSmall
        if (this.isSticky) {
            bgColor = this.config.headerBgColorSmallSticky
        } else if (this.state.isWindowScrollYZero && !this.state.coverUp) {
            bgColor = this.config.headerBgColorLarge
        }

        // 第３引数は、前のtoに被せてアニメーションを開始させる時間を指定している
        tween
        .to(this.headerContainerEl.current, {
            duration: immediate ? 0 : 0.5,
            ease: easePolyOut,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: bgColor,
        }, "-=0.5")
    }


    // ヘッダーの状態を大きくする
    addAnimationHeaderStateLarge(tween: any, immediate: boolean = false): void {
        tween
        .to(this.headerContainerEl.current, {
            duration: immediate ? 0 : 0.5,
            ease: easePolyIn,
            paddingTop: this.config.headerOpenLargePaddingTop,
            paddingBottom: this.config.headerOpenLargePaddingBottom,
            backgroundColor: this.config.headerBgColorLarge,
        })
    }


    // ヘッダーを画面外へ上移動する
    addAnimationHeaderMoveOffScreenTop(tween: any, immediate: boolean = false): void {
        let innerContainerHeight = 0
        if (typeof window !== "undefined") {
            innerContainerHeight = this.headerContainerEl.current.clientHeight
        }

        tween
        .to(this.headerWrapperEl.current, {
            duration: immediate ? 0 : 0.5,
            ease: easePolyInOut,
            top: -(innerContainerHeight),
            opacity: 0,
        })
    }


    // ヘッダーを画面上部に戻す
    addAnimationHeaderMoveOnScreenTop(tween: any, immediate: boolean = false): void {
        tween
        .to(this.headerWrapperEl.current, {
            duration: immediate ? 0 : 0.3,
            ease: easePolyInOut,
            top: 0,
            opacity: 1,
        })
    }


    // ヘッダーメニューを開く
    addAnimationHeaderMenuOpen(tween: any, immediate: boolean = false): void {
        const className = this.config.menuListItemClassNameTweenTarget

        tween
        .to(className, {
            duration: immediate ? 0 : 0.5,
            ease: easePolyInOut,
            stagger: 0.03,
            opacity: 1,
            marginTop: 20,
            marginBottom: 20,
            height: 40,
        })
    }


    // ヘッダーメニューを閉じる
    addAnimationHeaderMenuClose(tween: any, immediate: boolean = false): void {
        const className = this.config.menuListItemClassNameTweenTarget

        tween
        .to(className, {
            duration: immediate ? 0 : 0.5,
            ease: easePolyInOut,
            stagger: -0.03,
            opacity: 0,
            marginTop: 0,
            marginBottom: 0,
            height: 0,
        })
    }


    // トピックパスを開く
    addAnimationTopicPathOpen(tween: any, immediate: boolean = false): void {
        tween
        .to(this.topicPathEl.current, {
            duration: immediate ? 0 : 0.3,
            ease: easePolyInOut,
            height: 90,
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
        })
    }


    // トピックパスを閉じる
    addAnimationTopicPathClose(tween: any, immediate: boolean = false): void {
        tween
        .to(this.topicPathEl.current, {
            duration: immediate ? 0 : 0.3,
            ease: easePolyInOut,
            height: 0,
            borderTop: "none",
        })
    }


    // サイトマップボタンコンテナをヘッダーを開いたときのポジションにする
    addAnimationSitemapButtonContainerOpenPosition(tween: any): void {
        let isNarrowScreenWidth = false
        if (this.state.screenSize == "small" ||
            this.state.screenSize == "medium"
        ) {
            isNarrowScreenWidth = true
        }

        let isNarrowScreenHeight = false
        if (this.state.windowHeight < this.config.windowMinimumHeightToEnableInlineScrollingHeader) {
            isNarrowScreenHeight = true
        }

        tween
        .to(this.sitemapButtonContainerEl.current, {
            duration: 0,
            position: isNarrowScreenWidth ? "relative" : isNarrowScreenHeight ? "relative" : "absolute",
            right: 0,
            left: 0,
        })
    }


    // サイトマップボタンコンテナをヘッダーを閉じたときのポジションにする
    addAnimationSitemapButtonContainerClosePosition(tween: any): void {
        tween
        .to(this.sitemapButtonContainerEl.current, {
            duration: 0,
            position: "absolute",
            right: 0,
            left: 0,
        })
    }


    // サイトマップボタンを表示する
    addAnimationDisplaySitemapButton(tween: any, immediate: boolean = false): void {
        let isNarrowScreenWidth = false
        if (this.state.screenSize == "small" ||
            this.state.screenSize == "medium"
        ) {
            isNarrowScreenWidth = true
        }

        let isNarrowScreenHeight = false
        if (this.state.windowHeight < this.config.windowMinimumHeightToEnableInlineScrollingHeader) {
            isNarrowScreenHeight = true
        }

        tween
        .to(this.sitemapButtonEl.current, {
            duration: 0,
            display: "block",
            top: -30,
            /*
             * 1) スクリーン幅が small, medium なら、top: -15px
             * 2) スクリーン幅が large でスクリーン高さが設定値より低ければ、top: -15px
             * 3) スクリーン幅が large でスクリーン高さが設定値より高ければ、top: 30px
             */
            //top: isNarrowScreenWidth ? -15 : isNarrowScreenHeight ? -15 : 30,
        })
        .to(this.sitemapButtonEl.current, {
            duration: immediate ? 0 : 0.3,
            ease: easePolyInOut,
            opacity: 1,
        })
    }


    // サイトマップボタンを隠す
    addAnimationHideSitemapButton(tween: any, immediate: boolean = false): void {
        tween
        .to(this.sitemapButtonEl.current, {
            duration: immediate ? 0 : 0.3,
            ease: easePolyInOut,
            opacity: 0,
        })
        .to(this.sitemapButtonEl.current, {
            duration: 0,
            display: "none",
        })
    }


    // サイトマップを開く
    addAnimationSitemapOpen(tween: any, immediate: boolean = false): void {
        tween
        .to(this.sitemapEl.current, {
            duration: immediate ? 0 : 0.6,
            ease: easePolyInOut,
            height: "auto",
        })
    }


    // サイトマップを閉じる
    addAnimationSitemapClose(tween: any, immediate: boolean = false): void {
        tween
        .to(this.sitemapEl.current, {
            duration: immediate ? 0 : 0.6,
            ease: easePolyInOut,
            height: 0,
        })
    }


    // ヘッダーコンテナ内のスクロールを有効にする
    addAnimationEnableInnerScrollToHeader(tween: any, immediate: boolean = false) {
        tween
        .to(this.headerEl.current, {
            duration: immediate ? 0 : 0.4,
            ease: easePolyInOut,
            height: "100vh",
            overflowY: "scroll",
            overflowX: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        })
        // body要素に付けたスクロールさせないスタイルクラスを取り除く
        .call(() => this.addStyleClassDoNotScrollToBody())
    }


    // ヘッダーコンテナ内のスクロールを無効にする
    addAnimationDisableInnerScrollToHeader(tween: any, immediate: boolean = false) {
        tween
        .to(this.headerEl.current, {
            duration: immediate ? 0 : 0.4,
            ease: easePolyInOut,
            height: "auto",
            overflowY: "hidden",
            overflowX: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0)",
        })
        // body要素にスクロールさせないスタイルクラスを付ける
        .call(() => this.removeStyleClassDoNotScrollToBody())
    }


    // ヘッダーを小さく開く
    openHeaderSmall(): void {
        this.addActionChangeHeaderStateSmall(this.tween)
        this.tween.play()
    }


    // ヘッダーを大きく開く
    openHeaderLarge(): void {
        this.addActionChangeHeaderStateLarge(this.tween)
        this.tween.play()
    }


    // displayStateの値によってヘッダーを開閉する
    changeHeaderInDisplayState(displayState: string): void {
        if (displayState == "large") {
            this.openHeaderLarge()
            return
        }

        this.openHeaderSmall()
    }


    // ヘッダーを追随させるか切り替える
    switchHeaderSticky(): void {
        // すべてのアニメーションとコールバックをキャンセルする
        this.tween.totalProgress(1)
        //this.tween.clear()
        this.disableAddAnimation = false
        this.tween.eventCallback("onComplete", null)

        // アニメーションの再生が終了するまで、他からのアニメーション追加をロックする。
        //this.addConfigLockingAddAnimation(this.tween)

        // サイトマップが開いていれば閉じる
        if (this.state.isSitemapDisplayed) {
            this.addActionSitemapClose(this.tween)
        }

        // スクロール量が 0 (一番上）のとき
        if (this.state.isWindowScrollYZero) {
            this.addActionHeaderNotSticky(this.tween)
            this.tween.play()
            return
        }

        // スクロール量が 0 以上のとき
        this.addActionHeaderSticky(this.tween)
        this.tween.play()
    }


    // サイトマップを開く
    sitemapOpen(): void {
        this.setState({ isSitemapDisplayed: true })
    }


    // サイトマップを閉じる
    sitemapClose(): void {
        this.setState({ isSitemapDisplayed: false })
    }


    // windowsリサイズ時に実行する処理
    windowResize(): void {
        this.updateWindowHeight()
        this.updateWindowWidth()
        this.updateInnerContainerHeight()
    }


    // windowsスクロール時に実行する処理
    windowScroll(event: any): void {
        this.updateScrollY()
        this.updateIsWindowScrollYZero()
        this.updateScrollDirection()
        this.updatePrevScrollY()

        // モバイル環境の処理
        if (this.state.screenSize == "small" || this.state.screenSize == "medium") {
            event.preventDefault()
            return
        }

        // デスクトップ環境の処理
        if (this.state.isSitemapDisplayed && this.isSticky) {
            this.sitemapClose()
        }


        // 設定したスクロール量まで下スクロールしたらヘッダーを隠す
        // if (this.scrollDirection == "down" && this.isSticky && this.scrollY > this.config.hideHeaderScrollY) {
        //     this.addAnimationHeaderMoveOffScreenTop(this.tween)
        //     this.tween.play()
        // }

        // 隠したヘッダーを表示する
        // if (this.scrollDirection == "up" && this.isSticky) {
        //     this.addAnimationHeaderMoveOnScreenTop(this.tween)
        //     this.tween.play()
        // }
    }


    // インナーコンテナの高さを更新する
    updateInnerContainerHeight(): void {
        const clientHeight = this.elementClientHeight(this.headerContainerEl)

        let height = 0
        if (clientHeight !== undefined) {
            height = clientHeight
        } else {
            console.warn(`The clientHeight could not be obtained.`)
        }
        height += this.config.headerOpenLargePaddingTop + this.config.headerOpenLargePaddingBottom

        this.setState({ innerContainerHeight: height })
    }


    // Windowの高さを更新する
    updateWindowHeight(): void {
        if (typeof window !== "undefined") {
            const height = window.innerHeight
            this.setState({ windowHeight: height })
        }
    }


    // Windowの幅を更新する
    updateWindowWidth(): void {
        if (typeof window !== "undefined") {
            const width = window.innerWidth
            this.setState({ windowWidth: width })
        }
    }


    // Windowの縦スクロール量を更新する
    updateScrollY(): void {
        const scrollY = this.windowScrollY()
        this.scrollY = scrollY !== undefined ? scrollY : 0
    }


    // 現在のWindowsの縦スクロール量を更新する
    updatePrevScrollY(): void {
        this.prevScrollY = this.scrollY
    }


    // スクロール方向を更新する
    updateScrollDirection(): void {
        if (this.scrollY > this.prevScrollY) {
            this.scrollDirection = "down"
        } else {
            this.scrollDirection = "up"
        }
    }


    // Windowの縦スクロール位置が 0 かを更新する
    updateIsWindowScrollYZero(): void {
        if (this.scrollY <= 0) {
            //if (!this.state.isWindowScrollYZero) this.setState({ isWindowScrollYZero: true })
            this.setState({ isWindowScrollYZero: true })
        } else {
            //if (this.state.isWindowScrollYZero || this.state.isWindowScrollYZero == undefined) this.setState({ isWindowScrollYZero: false })
            this.setState({ isWindowScrollYZero: false })
        }
    }


    // サイトマップの表示状態を更新する
    updateIsSitemapDisplayed(isSitemapDisplayed: boolean): void {
        this.setState({ isSitemapDisplayed: isSitemapDisplayed })
    }


    // Body要素にスクロールを禁止するスタイルクラスを追加する
    addStyleClassDoNotScrollToBody(): void {
        if (typeof window !== "undefined") {
            const elements = document.getElementsByTagName("body")
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add("common-body-do-not-scroll")
            }
        }
    }


    // Body要素のスクロールを禁止するスタイルクラスを削除する
    removeStyleClassDoNotScrollToBody(): void {
        if (typeof window !== "undefined") {
            const elements = document.getElementsByTagName("body")
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove("common-body-do-not-scroll")
            }
        }
    }


    // 要素の高さを返す
    elementClientHeight(element: React.RefObject<HTMLElement>): number | undefined {
        if (typeof window !== "undefined") {
            return element.current !== null ? element.current.clientHeight : undefined
        }
    }


    // Windowのスクロール量を返す
    windowScrollY(): number | undefined {
        if (typeof window !== "undefined") {
            return window.pageYOffset !== undefined ? window.pageYOffset : document.documentElement.scrollTop
        }
    }


    render() {
        return (
            <Fragment>
                <header>
                    <div
                        ref={this.headerWrapperEl}
                        className="header-wrapper"
                    >
                        <div
                            ref={this.headerEl}
                            className="header"
                        >
                            <div
                                ref={this.headerContainerEl}
                                className="grid-container full header-container"
                                style={{ height: "auto" }}
                            >
                                <div className="grid-x grid-padding-x header-bar">
                                    <div className="cell small-8 small-order-1 large-shrink header-bar__cell1">
                                        <Link to="/">
                                            <h1 className="header-logo">
                                                <SvgLogo
                                                    title={this.props.siteTitle}
                                                    fill={this.state.svgLogoFillColor}
                                                    furigana={this.props.siteTitle}
                                                    delay={this.displayState == "small" ? 1.2 : 0}
                                                />
                                            </h1>
                                        </Link>
                                    </div>
                                    <div
                                        className="cell large-auto small-order-3 large-order-2 align-self-stretch header-bar__cell2">
                                        <HeaderMenu
                                            menuList={this.config.menuList}
                                            isHeaderOpened={this.displayState == "large"}
                                            immediate={this.headerMenuImmediate}
                                        />
                                    </div>
                                    <div
                                        className="cell small-4 small-order-2 large-shrink align-self-stretch header-bar__cell3">
                                        <MenuButton
                                            size="60px"
                                            displayState={this.displayState}
                                            onClickCallback={(displayState: string) => this.changeHeaderInDisplayState(displayState)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                ref={this.topicPathEl}
                                className="header__topic-path-container"
                                style={{ height: 0 }}
                            >
                                <TopicPath
                                    pageId={this.props.pageId}
                                    crumbs={this.props.crumbs}
                                />
                            </div>
                            <div
                                ref={this.sitemapButtonContainerEl}
                                className="header__site-map-button-container"
                            >
                                <div className="grid-container full">
                                    <div className="grid-x">
                                        <div className="cell small-12">
                                            <div className="grid-container">
                                                <div className="gird-x grid-padding-x">
                                                    <div className="cell small-12">
                                                        <div
                                                            ref={this.sitemapButtonEl}
                                                            className="header__site-map-button-wrapper"
                                                            style={{
                                                                width: "60px",
                                                                margin: "0 auto",
                                                                display: "none",
                                                                opacity: 0,
                                                            }}
                                                        >
                                                            <SiteMapButton
                                                                size="60px"
                                                                isSitemapDisplayed={this.state.isSitemapDisplayed}
                                                                onClickCallback={(isSitemapDisplayed: boolean) => this.updateIsSitemapDisplayed(isSitemapDisplayed)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                ref={this.sitemapEl}
                                className="header__site-map-container"
                                style={{ height: 0 }}
                            >
                                <FooterMenu className="header__site-map"/>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}


/**
 * SVG Logo component
 *
 * @param {string} title - SVG内のtitle要素に入るテキスト
 * @param {string} fill - 塗りつぶし色
 * @param {string} defaultFill? - マウント時の塗りつぶし色
 * @param {string} furigana? - ロゴのふりがな
 * @param {number} delay? - アニメーションを再生するまでの遅延時間
 */
interface ISvgLogoProps {
    title: string,
    fill?: string,
    defaultFill?: string,
    furigana: string,
    delay?: number,
}

const SvgLogo = (props: ISvgLogoProps) => {
    const groupRef = useRef(null)
    const furiganaRef = useRef(null)
    const tween = gsap.timeline({ paused: true })
    let prevFill = props.defaultFill


    useEffect(() => {
        tween.from(groupRef.current, {
            fill: prevFill,
        })
        tween.play()
    }, [])


    useEffect(() => {
        fillChange()
    }, [props.fill])


    const fillChange = () => {
        tween.clear()
        tween
        .to([groupRef.current, furiganaRef.current], {
            delay: props.delay,
            duration: 1.0,
            ease: easePolyInOut,
            color: props.fill,
            fill: props.fill,
        })
        tween.play()

        prevFill = props.fill
    }


    return (
        <Fragment>
            <svg
                viewBox="0 0 250 15"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "100%" }}
            >
                <title>{props.title}</title>
                <g ref={groupRef}>
                    <path
                        d="M195.4 11.264a.843.843 0 0 1-.9.738h-7.191a.832.832 0 0 1-.899-.738V3.786a.832.832 0 0 1 .899-.748h7.191a.844.844 0 0 1 .9.748v7.478zm0-11.217h-9c-2.49 0-4.5 1.678-4.5 3.739v7.478c0 2.021 2 3.729 4.5 3.729h9c2.479 0 4.5-1.667 4.5-3.729V3.786c0-2.021-2-3.739-4.5-3.739M172.2 5.656a.842.842 0 0 1-.9.748h-5.85v2.243h5.85a.842.842 0 0 1 .9.748v2.607a.843.843 0 0 1-.9.749h-8.1V2.291h8.1a.842.842 0 0 1 .9.748v2.617zm4.52-.374V3.786c0-2.071-2-3.739-4.5-3.739h-13.49v14.946h13.49c2.48 0 4.5-1.667 4.5-3.729V9.76c-.147-1.374-1.361-2.37-2.72-2.235 1.355.124 2.557-.876 2.7-2.243M125.18 14.994h17.99V12.75h-13.49V8.647h13.49V6.404h-13.49V2.291h13.49V.048h-17.99v14.946zM102.09 3.786v7.478c0 2.021 2 3.729 4.5 3.729h9a4.391 4.391 0 0 0 4.41-3.031h-12.5a.833.833 0 0 1-.9-.738V3.786a.833.833 0 0 1 .9-.748H120a4.391 4.391 0 0 0-4.41-3.031h-9c-2.49 0-4.5 1.677-4.5 3.739M78.91 14.994h4.5V5.03l10.11 9.964h3.37V.048H92.4v9.964L82.28.048h-3.37v14.946zM51.28 14.994h4.49l6.72-11.207 6.72 11.207h4.5L64.74.048h-4.49l-8.97 14.946zM40.48 6.404a.843.843 0 0 1-.937.738H31.43V2.291h8.1a.843.843 0 0 1 .9.748l.05 3.365zm1 2.981h.75c1.362.122 2.568-.888 2.7-2.264v-4.83C44.788.923 43.585-.077 42.23.048H26.94v14.946h4.49V9.385h3.31l7.2 5.609h6.74l-7.2-5.609zM16.81.048H0l2.2 2.243h10.11v12.702h4.49V2.291l.01-2.243zM218.58 11.264a.833.833 0 0 1-.9.738h-7.19a.833.833 0 0 1-.9-.738V3.786a.832.832 0 0 1 .9-.748h7.19a.832.832 0 0 1 .9.748v7.478zm0-11.217h-9c-2.49 0-4.5 1.678-4.5 3.739v7.478c0 2.021 2 3.729 4.5 3.729h9c2.49 0 4.5-1.667 4.5-3.729V3.786c0-2.021-2-3.739-4.5-3.739M233.2.048v14.945h4.5V2.291h10.08L250 .048h-16.8z"/>
                </g>
            </svg>
            <p
                ref={furiganaRef}
                className="header-logo__name"
            >
                {props.furigana}
            </p>
        </Fragment>
    )
}

SvgLogo.defaultProps = {
    defaultFill: `rgb(0, 0, 0)`,
    furigana: "",
    delay: 0,
}


export default HeaderV2