import React from "react"
import Helmet from "react-helmet"


/**
 * FontAwesomeKits
 * Font Awesome のKitsサービスを使ってフォントを取得する
 */
const FontAwesomeKits = () => (
    <script src="https://kit.fontawesome.com/564fd7898c.js" crossOrigin="anonymous"></script>
)


/**
 * <head>にKitsのスクリプトを埋め込む
 * @return {*} React Element
 */
const FontAwesome = () => <Helmet>{FontAwesomeKits()}</Helmet>


export default FontAwesome
