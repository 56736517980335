import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"


/**
 * HeaderMenuItem component
 *
 * @param href {string} -
 * @param active {boolean} -
 * @param isHeaderOpend {boolean} -
 * @return React Element {*}
 */
interface HeaderMenuitemProps {
    children: any,
    href: string,
    className: string,
    style: any,
    active: boolean,
}

const HeaderMenuItem = (props: HeaderMenuitemProps) => {
    const [menuItemStyle, setMenuItemStyle] = useState("")

    useEffect(() => {
        if (props.active) {
            setMenuItemStyle("col small-12 medium-auto header-menu-item active")
        } else {
            setMenuItemStyle("col small-12 medium-auto header-menu-item")
        }
    }, [props.active])

    return (
        <li className={props.className} style={props.style}>
            <Link
                className="header-menu-item__anchor"
                activeClassName="active"
                to={props.href}
            >
                {props.children}
            </Link>
        </li>
    )
}
HeaderMenuItem.defaultProps = {
    href: "/",
    active: false,
    className: "",
    style: {},
}


export default HeaderMenuItem